import {Component} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { ToastrService } from 'ngx-toastr';

import {UploadFilesService} from "./upload.service";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent {
  public page:any = '/list';
  public tabIndex : any = 1;
  public isBundle : any = false;
  public payments: any;
  public matched: any = [];
  public postData: any = {};
  public ages: any = [];
  public agelimits: any = [];
  public errorText: any = '';
  public respTpi: any = '';
  public ownerRegno: any = '';
  public propNo: any = '';
  public prod: any = 1;
  public property: any;
  public properties: any = [];
  public localVisitor: any = {};
  public owners: any = [];
  public selectedServiceId: any = 1;
  public refs: any;
  public title: any;
  public token: any;
  public user: any;
  public users: any;
  public login: any;
  public editing: any;
  public orgs: any;
  public agents: any;
  public status: any;
  public json: any;
  public pageId: any;
  public jsonArr: any = [];
  public invoices: any = [];
  public error: any = [];
  public plates: any = [];
  public aploading: any = false;
  public gloading: any = false;
  public kloading: any = false;
  public groups:any;
  public invoice: any;
  public jsonLoad: any = false;
  public countryLoad: any = false;
  public invoiceLoading: any = false;
  public countries: any = [];
  public aimags: any = [];
  public services: any = [];
  public age1: any = [];
  public age2: any = [];
  public age3: any = [];
  public age4: any = [];
  public age5: any = [];
  public age12: any = [];
  public age13: any = [];
  public pages: any = [];
  public profile: any;
  public paylinks: any;
  public payresp: any;
  public newInvoice: any;
  public selOrgId: any = 0;
  public selAgentId: any = 0;
  public selRegion: any = 'EU';
  public selItemType: any = 'A1';

  public selLocalWho: any = 'A1';
  public selService: any;

  public fileInfos: any = [];
  public loading: any = false;
  public payLoading: any = false;
  public payLoading1: any = false;
  public pageNum: any = 0;
  public viewMode: any = 2;
  public firstload: any = false;
  public companies: any;
  public selInvoice: any;
  public rate: any = 0;
  public serviceCombined: any = [];
  public rules: any;
  public resp : any;
  public searchCountry: any;
  public listMode: any = 2;
  public modalHeight: any = 680;
  constructor(
    public http: HttpClient,
    public route: ActivatedRoute,
    public location: Location,
    public toastr: ToastrService,
    public uploadService: UploadFilesService
  ) {
    this.user = {
      email: '',
      password: ''
    };
    this.paylinks = {
      qr: ''
    };
    this.payresp = {
      payStatus: 1
    };
    this.resp = {
      code: 0,
      msg: ''
    };
    this.login = false;
    this.token = localStorage.getItem("token");
    this.refs = [];
    for (let i = 0; i <= 12; i++)
      this.refs[i] = [];


    this.listMode = window.innerWidth < 1000 ? 1 : 2;
    this.modalHeight = this.listMode == 1 ? 680 : 680;

    this.aimags = [
      {code:'01',name:'Архангай',region:'MN'}, {code:'02',name:'Баянхонгор',region:'MN'}, {code:'03',name:'Баян-өлгий',region:'MN'},
      {code:'04',name:'Булган',region:'MN'}, {code:'05',name:'Говь-алтай',region:'MN'}, {code:'06',name:'Говь-сүмбэр',region:'MN'},
      {code:'07',name:'Дархан-уул',region:'MN'}, {code:'08',name:'Дорноговь',region:'MN'}, {code:'09',name:'Дорнод',region:'MN'},
      {code:'10',name:'Дундговь',region:'MN'}, {code:'11',name:'Завхан',region:'MN'}, {code:'12',name:'Орхон-уул',region:'MN'},
      {code:'13',name:'Өвөрхангай',region:'MN'}, {code:'14',name:'Өмнөговь',region:'MN'}, {code:'15',name:'Сэлэнгэ',region:'MN'},
      {code:'16',name:'Сүхбаатар',region:'MN'},{code:'17',name:'Төв',region:'MN'},
      {code:'18',name:'Ховд',region:'MN'}, {code:'19',name:'Хөвсгөл',region:'MN'}, {code:'20',name:'Хэнтий',region:'MN'},
      {code:'21',name:'Увс',region:'MN'}
    ];
  }


  initZip() {
    if (this.jsonLoad == false) {
      this.jsonLoad = true;
      this.http.get('assets/zip.json').subscribe((res) => {
        this.json = res;
        this.loadZip();
      }, error => {
      });
    }
  }

  loadAges() {
    const url = '/p/v1/ages';
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + this.token
    })

    this.http.get(url, {headers: headers}).subscribe((res) => {
      this.ages = res;
    }, error => {
    });
  }

  loadAgeLimits() {
    const url = '/p/v1/agelimits';
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + this.token
    })

    this.http.get(url, {headers: headers}).subscribe((res) => {
      this.agelimits = res;
    }, error => {
    });
  }

  loadZip() {
    this.refs = [];
    this.refs[0] = [];
    this.refs[1] = [];
    this.refs[2] = [];
    this.jsonArr = [];
    for (let i = 0; i < this.json.length; i++) {
      let p = {
        code: this.json[i].zipcode,
        name: this.json[i].name_mn
      };
      this.refs[0].push(p);
      this.jsonArr.push(p);

      for (let j = 0; j < this.json[i].l2.length; j++) {
        let pz = {
          code: this.json[i].l2[j].zipcode,
          name: this.json[i].l2[j].name_mn
        };
        this.jsonArr.push(pz);
      }
    }
  }

  initRefrences() {
    for (let i = 0; i <= 1; i++)
      this.getRefrences(i, 0);
    //this.getRefrences(11, 0);
  }

  getToken() {
    if (this.login) return;
    this.login = true;
    const url = '/p/v1/auth/authenticate';
    this.http.post(url, this.user).subscribe((res) => {
      this.token = res;
      if (this.token && this.token.token) {
        localStorage.setItem('token', this.token.token);
        localStorage.setItem('time', new Date().getTime()+'');
        location.href = "/list";
        this.login = false;
      }
    }, error => {
      this.toastr.error('Хандалт буруу !', 'Алдаа !');
      this.login = false;
    });
  }

  getRefrences(type: any, value: any) {
    const url = 'p/hub/refrence/' + (type % 10) + '/' + value;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + this.token
    })

    this.http.get(url, {headers: headers}).subscribe((res) => {
      this.refs[type] = res;
    }, error => {
      this.toastr.error('Хандалт буруу !', 'Алдаа !');
      this.login = false;
      this.logout();
    });
  }

  getProfile() {
    if (this.token == '') {
      this.logout();
      return;
    }
    let time = localStorage.getItem('time');
    let curr = new Date().getTime();
    if (time && parseInt(time) > 0) {
      let t = curr - parseInt(time)
      if (t > 10 * 60 * 1000) {
        this.logout();
        return;
      }
    }

    const url = 'p/v1/auth/profile';
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + this.token
    })
    this.http.get(url, {headers: headers}).subscribe((res) => {
      this.profile = res;
      if (this.profile.role == 'ROLE_API') {
        this.logout();
        return;
      }
      this.getRules();
      localStorage.setItem('time', new Date().getTime()+'');
    }, error => {
      this.logout();
      localStorage.setItem('token', '');
      this.token = '';
    });
  }

  getServiceCombined() {
    const url = 'p/serviceCombined';
    this.http.get(url).subscribe((res) => {
      this.serviceCombined = res;
    }, error => {
    });
  }

  listCombines(protectId: any) {
    let ls = this.selService.packageList;
    let ret = '';
    for (let i = 0; i < this.serviceCombined.length; i++) {
      let t = this.serviceCombined[i];
      if (t.combineId == protectId) {
        let p = t.protects.split(',');
        for (let pp = 0; pp < p.length; pp++) {
          for (let l = 0; l < ls.length; l++) {
            let j = ls[l];
            for (let f = 0; f < j.protectList.length; f++) {
              if (p[pp] == j.protectList[f].id)
                ret += j.protectList[f].descr+'+';
            }
          }
        }
      }
    }

    return ret;
  }

  getRate() {
    const url = 'p/v1/rate/USD';
    this.http.get(url).subscribe((res) => {
      this.rate = res;
    }, error => {
    });
  }
  getOrgs() {
    const url = 'p/orgs/2';
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + this.token
    })
    this.http.get(url, {headers: headers}).subscribe((res) => {
      this.orgs = res;
    }, error => {
      // this.logout();
    });
  }

  getAgents() {
    const url = 'p/orgs/3';
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + this.token
    })
    this.http.get(url, {headers: headers}).subscribe((res) => {
      this.agents = res;
      if (this.agents.length == 1)
        this.selAgentId = this.agents[0].id;
    }, error => {
      // this.logout();
    });
  }

  getCountries() {
    const url = 'p/countryList';
    this.http.get(url).subscribe((res) => {
      this.countries = res;
    }, error => {
      this.logout();
    });
  }

  getServices() {
    const url = 'p/serviceList';
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer '+this.token
    })
    this.http.get(url, {headers: headers}).subscribe((res) => {
      this.services = res;
    }, error => {
      this.logout();
    });
  }

  getRules() {
    const url = 'p/v1/rules/'+this.profile.org_id;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + this.token
    })
    this.http.get(url, {headers: headers}).subscribe((res) => {
      this.rules = res;
    }, error => {
    });
  }

  selCountries(country: any) {
    this.newInvoice.countries.push(country);
    this.newInvoice.region = country.region;
    for (let i = 0; i < this.newInvoice.countries.length; i++) {
      if (this.newInvoice.countries[i].region == 'EU') {
          this.newInvoice.region = 'EU';
          return;
      }
    }

    for (let i = 0; i < this.newInvoice.countries.length; i++) {
      if (this.newInvoice.countries[i].region == 'UCJ') {
        this.newInvoice.region = 'UCJ';
        return;
      }
    }

    for (let i = 0; i < this.newInvoice.countries.length; i++) {
      if (this.newInvoice.countries[i].region == 'WW') {
        this.newInvoice.region = 'WW';
        return;
      }
    }
  }

  selAimags(aimag: any) {
    this.newInvoice.countries.push(aimag);
  }

  isselectedCountry(country: any) {
    for (let i = 0; i < this.newInvoice.countries.length; i++) {
      if (this.newInvoice.countries[i].name == country.name) {
        return true;
      }
    }

    return false;
  }

  removeCountries(country: any) {
    for (let i = 0; i < this.newInvoice.countries.length; i++) {
      if (this.newInvoice.countries[i].name == country.name)
        this.newInvoice.countries.splice(i, 1);
    }
  }

  logout() {
    const url = '/p/v1/auth/logout';
    this.http.get(url,).subscribe((res) => {
      localStorage.setItem('token', '');
      localStorage.setItem('time', '0');
      location.href = '/';
    });
  }

  logged() {
    return this.token != '';
  }

  pushAges(array: any, type: any) {
    if (this.newInvoice.groupId > 0) {
      return;
    }
    array.push({
        rd:'',
        ep: '',
        fn: '',
        ln: '',
        ph: '',
        em: '',
        sd: '',
        ed: '',
        fgn: 0,
        status: 1,
        type: type
    });
    this.newInvoice.age1 = this.age1.length;
    this.newInvoice.age2 = this.age2.length;
    this.newInvoice.age3 = this.age3.length;
    this.newInvoice.age4 = this.age4.length;
    this.newInvoice.age5 = this.age5.length;
  }

  popAges(array: any) {
    if (this.newInvoice.groupId > 0) {
      return;
    }
    if (array.length > 0) {
      array.splice(array.length - 1, 1);
    }
    this.newInvoice.age1 = this.age1.length;
    this.newInvoice.age2 = this.age2.length;
    this.newInvoice.age3 = this.age3.length;
    this.newInvoice.age4 = this.age4.length;
    this.newInvoice.age5 = this.age5.length;
  }

  protectSelect(protects: any, protect: any) {
    if (!protect || protect.selection == 0) return;

    if (this.newInvoice.protectId == protect.id &&  protect.state == 1) {
      protect.state = 0;
      return;
    }
    if (this.selectedServiceId == 1) {
      for (let i = 0; i < protects.length; i++) {
        protects[i].state = 0;
      }
      protect.state = 1;
    } else {
      protect.state = 1 - protect.state;
    }

    this.newInvoice.protectId = protect.id;
  }

  selectService(serviceId:any) {
    this.selectedServiceId = serviceId;
    for (let i = 0; i < this.services.length; i++) {
      if (this.services[i].id == serviceId) {
        this.selService = this.services[i];
        this.pageId = 1;

        for (let i = 0; i < this.selService.packageList.length; i++) {
          let p = this.selService.packageList[i].protectList;
          for (let j = 0; j < p.length; j++) {
            if (p[j].selection == 1 && p[j].state == 1 && (serviceId != 5 || (serviceId == 5 && p[j].regions.indexOf(this.selItemType) != -1))) {
              this.newInvoice.protectId = p[j].id;
            }
          }
        }
      }
    }

    if (this.selService && this.selService.id == 1) {
      this.newInvoice.visaType = "1";
    } else
    if (this.selService && (this.selService.id == 2 || this.selService.id == 3)) {
      this.owners = [];
      this.properties = [{
        type: 3,
        regno: '',
        amount: 0,
        size: 0,
        address: '',
        propYear: '',
        mnYear: '',
        mark: '',
        model: '',
        quality: 1,
        safety: 1,
        location: 1,
        off: '',
        sub: '',
        town: '',
        apart: '',
        number: ''
      }];
      if (this.selService.id == 2) {
        this.pushOwner();
        this.properties[0].cat = 1;
      }

      this.fileInfos = [];
      this.newInvoice.days = 365;
      this.newInvoice.endDate =  this.yyyymmdd(this.addDays(new Date(), this.newInvoice.days));
      this.newInvoice.visaType = (this.selService.id == 3 || this.selService.id == 2) ? parseInt(this.newInvoice.visaType) : 3;
      this.newInvoice.amount = 0;
      this.selItemType = 'A1';
    } else
    if (this.selService && this.selService.id == 4) {
      this.selItemType = 'J1';
      this.fileInfos = [];
      if (!this.isBundle) {
        this.owners = [{
          fn: '',
          ln: '',
          em: '',
          ph: '',
          rn: '',
          dl: '',
          am: 0,
          tr: 1
        }];
        this.properties = [{
          type: 4,
          regno: '',
          amount: 0,
          size: 0,
          address: '',
          mark: '',
          model: '',
          propYear: '',
          quality: 1,
          safety: 1,
          location: 1,
          capacity: 0,
          extend: 0
        }];
      } else {

      }
      this.newInvoice.days = 365;
      this.newInvoice.endDate =  this.yyyymmdd(this.addDays(new Date(), this.newInvoice.days));

    } else
    if (this.selService && this.selService.id == 5) {
      this.selItemType = 'A1';
      this.newInvoice.days = 60;
      this.localVisitor = {
        passNo: '',
        coverage: 5000000,
        carNo: '',
        firstName: '',
        lastName: '',
        travelPoints: '',
        phoneNo: '',
        phoneNoMng: '',
        visaLength: 30,
        visaType: '',
        country: '',
        birthDate: new Date(),
        groupCount: 1,
        address: ''
      };
    }
  }

  addDays (date: any, days:any) {
    date.setDate(date.getDate() + days);
    return date;
  }

  yyyymmdd(d: any) {
      let date, month, year;

      date = d.getDate();
      month = d.getMonth() + 1;
      year = d.getFullYear();

      date = date
        .toString()
        .padStart(2, '0');

      month = month
        .toString()
        .padStart(2, '0');

      return `${year}-${month}-${date}`;
  }

  createInvoice(sd: any) {
    if (this.isBundle) return;
    if (sd == 3) {
      for (let i = 0; i < this.serviceCombined.length; i++)
        this.serviceCombined[i].state = 0;
    }

    this.localVisitor.coverage = 10000000;
    this.selectedServiceId = sd;
    this.companies = [];
    this.paylinks.qr = '';
    this.payresp = {};
    this.selOrgId = 0;
    this.propNo = '';
    this.owners = [];
    this.selItemType = 'A1';
    this.age1 = [];
    this.age2 = [];
    this.age3 = [];
    this.age4 = [];
    this.age5 = [];
    this.age12 = [];
    this.age13 = [];
    this.newInvoice = {
      daysType: 3,
      days: 0,
      age1: 0,
      age2: 0,
      age3: 0,
      age4: 0,
      age5: 0,
      age6: 0,
      age7: 0,
      age8: 0,
      age9: 0,
      age10: 0,
      age11: 0,
      age12: 0,
      orgId: 0,
      groupId: 0,
      serviceId: sd,
      visaType: "1",
      protectId: 0,
      amount: 0,
      region: '',
      startDate: this.yyyymmdd(new Date()),
      endDate: this.yyyymmdd(this.addDays(new Date(), 6)),
      descr: '',
      members: [],
      countries: []
    };
    this.selectService(sd);
  }

  diffDays() {
    return 1+((Date.parse(this.newInvoice.endDate) - Date.parse(this.newInvoice.startDate)) / (24 * 3600 * 1000));
  }

  rangeChange() {
    if (this.newInvoice.daysType == 3) {
      this.newInvoice.startDate = this.yyyymmdd(new Date());
      this.newInvoice.endDate = this.yyyymmdd(this.addDays(new Date(), 6));
    } else {
      this.newInvoice.startDate = this.yyyymmdd(new Date());
      this.newInvoice.endDate = this.yyyymmdd(this.addDays(new Date(), 365));
    }
  }

  calcAges(rd: any, ag: any) {
    let age = ag > 0 ? ag : this.checkAge(rd);
    for (let p = 0; p < this.ages.length; p++) {
      if (this.ages[p].s1 <= age && age <= this.ages[p].s2) {
        this.postData['age'+this.ages[p].field]++;
      }
    }
  }

  calcAgesSendBefore(rd: any, ag: any) {
    let age = ag > 0 ? ag : this.checkAge(rd);
    for (let p = 0; p < this.ages.length; p++) {
      if (this.ages[p].s1 <= age && age <= this.ages[p].s2) {
        this.newInvoice['age'+this.ages[p].field]++;
      }
    }
  }

  pushOwner() {
    this.owners.push({
      rd: '',
      fn: '',
      ln: '',
      em: '',
      dl: '',
      tr: 1,
      am: 0,
      ph: '',
      ownerType: 1
    });
  }

  removeOwner(owner: any) {
    for (let i = 0; i < this.owners.length; i++) {
      if (this.owners[i] == owner)
        this.owners.splice(i, 1);
    }
  }

  ps() {
    let ps = '';
    for (let i = 0; i < this.selService.packageList.length; i++) {
      let protects = this.selService.packageList[i].protectList;
      for (let j = 0; j < protects.length; j++) {
        if (this.selService.id == 2) {
          if (protects[j].state == 1)
            ps += protects[j].id + ',';
        }

        if (this.selService.id == 3 || this.selService.id == 4 || this.selService.id == 5) {
          if (protects[j].state == 1 && protects[j].regions.indexOf(this.selItemType) != -1)
            ps += protects[j].id + ',';
        }
      }
    }

    return ps;
  }

  checkInvoice() {
    this.tabIndex = 2;
    if (this.selectedServiceId == 1) {
      if (this.newInvoice.daysType == 4)
        this.newInvoice.days = 365;
      else
        this.newInvoice.days = this.diffDays();
    }

    this.newInvoice.members = [];

    if (this.selService.id == 1) {
      for (let i = 0; i < this.age1.length; i++) {
        this.age1[i].type = 1;
        this.newInvoice.members.push(this.age1[i]);
      }
      for (let i = 0; i < this.age2.length; i++) {
        this.age2[i].type = 2;
        this.newInvoice.members.push(this.age2[i]);
      }
      for (let i = 0; i < this.age3.length; i++) {
        this.age3[i].type = 3;
        this.newInvoice.members.push(this.age3[i]);
      }
      for (let i = 0; i < this.age4.length; i++) {
        this.age4[i].type = 4;
        this.newInvoice.members.push(this.age4[i]);
      }
      for (let i = 0; i < this.age5.length; i++) {
        this.age5[i].type = 5;
        this.newInvoice.members.push(this.age5[i]);
      }

      for (let i = 0; i < this.age12.length; i++) {
        this.age12[i].type = 12;
        this.newInvoice.members.push(this.age12[i]);
      }

      this.newInvoice.age1 = this.age1.length;
      this.newInvoice.age2 = this.age2.length;
      this.newInvoice.age3 = this.age3.length;
      this.newInvoice.age4 = this.age4.length;
      this.newInvoice.age5 = this.age5.length;
      this.newInvoice.age12 = this.age12.length;
      this.postData = {
        age1:this.age1.length,
        age2:this.age2.length,
        age3:this.age3.length,
        age4:this.age4.length,
        age5:this.age5.length,
        age6:0,
        age7:0,
        age8:0,
        age9:0,
        age10:0,
        age11:0,
        age12:this.age12.length,
        age13:0,
        region: this.newInvoice.region,
        visaType: this.newInvoice.visaType,
        days: this.newInvoice.days,
        protectId: this.newInvoice.protectId,
        countries: this.newInvoice.countries
      };

      if (this.newInvoice.groupId > 0) {
        this.postData.age1 = 0;
        this.postData.age2 = 0;
        this.postData.age3 = 0;
        this.postData.age4 = 0;
        this.postData.age5 = 0;
        this.postData.age6 = 0;
        this.postData.age7 = 0;
        this.postData.age8 = 0;
        this.postData.age9 = 0;
        this.postData.age10 = 0;
        this.postData.age11 = 0;
        this.postData.age12 = 0;
        this.postData.age13 = 0;

        for (let i = 0; i < this.newInvoice.members.length; i++) {
          this.calcAges(this.newInvoice.members[i].rd, this.newInvoice.members[i].age); //nasiig dahin huwaarilah
        }
      }
    } else
    if (this.selService.id == 2 || this.selService.id == 3 || this.selService.id == 4) {
      if (!this.properties[0].propYear && this.selService.id == 2) {
        this.toastr.error('Үл хөдлөх хөрөнгийн мэдээллийг татна уу !', 'Алдаа !');
        return;
      }

      if (!this.properties[0].propYear && (this.selService.id == 3 || this.selService.id == 4)) {
        this.toastr.error('Тээврийн хэрэгслийн мэдээллийг татна уу !', 'Алдаа !');
        return;
      }

      if (this.selService.id == 4) {
        if (!this.owners[0].dl) {
          // this.toastr.error('Жолооны үнэмлэхний дугаар оруулна уу !', 'Алдаа !');
          // return;
        }
        if (!this.owners[0].ph) {
          this.toastr.error('Утасны дугаар оруулна уу !', 'Алдаа !');
          return;
        }
        if (!this.owners[0].tr && this.properties[0].ownerType == 'Хувь хүн') {
          this.toastr.error('Туршлага оруулаагүй байна ! Жилээр оруулна уу.', 'Алдаа !');
          return;
        }

        if (this.plates.length > 0 && this.plates.length != this.properties.length) {
          this.toastr.error('Тээврийн хэрэгслүүдийг шалгана уу.', 'Алдаа !');
          return;
        }
      }

      if (this.selService.id == 2 || this.selService.id == 3) {
        if (!this.properties[0].amount || parseFloat(this.properties[0].amount) <= 0) {
          this.toastr.error('Үнэлгээ оруулаагүй байна !', 'Алдаа !');
          return;
        }
      }

      this.newInvoice.members = this.owners;
      this.newInvoice.users = this.owners;
      this.newInvoice.properties = this.properties;
      this.newInvoice.plates = this.plates;
      this.postData = this.newInvoice;
      let ps = '';
      if (this.selService.id == 2 || this.selService.id == 3 || this.selService.id == 4) {
        this.postData.type = this.selectedServiceId;
        this.postData.protects = this.ps();
        this.postData.region = this.selItemType;
        this.postData.serviceId = this.selectedServiceId;
      } else {

      }
    }
    else
    if (this.selService.id == 5) {
      if (!this.localVisitor.coverage) {
        this.toastr.error('Хамгаалалтын дүн сонгоно уу !', 'Алдаа !');
        return;
      }
      this.newInvoice.locals = this.localVisitor;
      this.newInvoice.region = this.selItemType;
      this.newInvoice.days = this.diffDays();
      this.newInvoice.serviceId = 5;
      if (this.selItemType == 'A1') {
        this.newInvoice.protectId = 77;
      } else {
        if (this.selItemType == 'A2' && this.newInvoice.protectId == 77)
          this.newInvoice.protectId = 78;
      }
      this.newInvoice.protects = this.ps();
      this.postData = {
        region: this.newInvoice.region,
        days: this.diffDays(), //this.newInvoice.days,
        protectId: this.newInvoice.protectId,
        locals: this.newInvoice.locals,
        protects: this.ps()
      };
    }

    const url = 'p/orgList/'+this.selService.id+'/2';
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer '+this.token
    })
    this.loading = true;
    this.gloading = true;
    this.companies = [];
    this.http.post(url, this.postData, {headers: headers}).subscribe((res) => {
      this.companies = res;

      if (this.companies.length == 0) {
        this.toastr.error('Мэдээлэл зөрүүтэй тул дахин шалгана уу', 'Алдаа !');
      }
      this.loading = false;
      this.gloading = false;

      this.ageSplices();
    }, error => {
      this.loading= false;
      this.toastr.error(error.statusText, 'Алдаа !');
    });
  }

  getPropertyPics(propNo: any) {

  }

  ageSplices() {
    for (let i = 0; i < this.newInvoice.members.length; i++) {
      let rd = this.newInvoice.members[i].rd;
      let age = this.newInvoice.members[i].age > 0 ? this.newInvoice.members[i].age : this.checkAge(rd);
      let month = this.newInvoice.members[i].age > 0 ? 0 : this.checkAgeMonth(rd);
      if (month > 0) {
        for (let j = 0; j < this.agelimits.length; j++) {
          let al = this.agelimits[j];
          if (al.s1 <= month && al.s2 >= month && al.type == 2) {
              for (let k = 0; k < this.companies.length; k++) {
                if (this.companies[k].id == al.org_id)
                  this.companies.splice(k, 1);
              }
          }
        }
      } else {
        for (let j = 0; j < this.agelimits.length; j++) {
          let al = this.agelimits[j];
          if (al.s1 <= age && al.s2 >= age && al.type == 1) {
            for (let k = 0; k < this.companies.length; k++) {
              if (this.companies[k].id == al.org_id) {
                this.companies.splice(k, 1);
              }
            }
          }
        }
      }
    }
  }

  getRefName(id:any, key: any) {
    for (let i = 0; i < this.refs[id].length; i++)
      if (this.refs[id][i].code == key)
        return this.refs[id][i].name;

    return '';
  }

  propertyCat(id: any) {
    switch(id) {
      case 1: return 'Орон сууц';
      case 2: return 'Барилга байгууламж';
      case 3: return 'Хашаа байшин';
      case 4: return 'Тусгай объект';
    }

    return id;
  }

  propUpdate(prop:any) {
    const url = 'p/propertyUpdate/'+prop.id;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer '+this.token
    })

    this.http.post(url, prop, {headers: headers}).subscribe((res) => {
      this.toastr.success('Амжилттай', 'Амжилттай !');
    }, error => {
      this.loading= false;
      this.payLoading= false;
      this.toastr.error(error.statusText, 'Алдаа !');
    });
  }

  sendInvoice(invoiceNo:any) {
    if (this.newInvoice.startDate == this.newInvoice.endDate) {
      this.toastr.error('Эхлэх, дуусах огноо буруу байна !', 'Алдаа !');
      return;
    }
    const url = 'p/v1/invoice/create/'+this.selService.id+'/'+invoiceNo;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer '+this.token
    })
    this.payLoading = true;
    this.loading = true;
    if (invoiceNo == 0 && this.selService.id == 1) {
      this.newInvoice.age1 = 0;
      this.newInvoice.age2 = 0;
      this.newInvoice.age3 = 0;
      this.newInvoice.age4 = 0;
      this.newInvoice.age5 = 0;
      this.newInvoice.age7 = 0;
      this.newInvoice.age8 = 0;
      this.newInvoice.age9 = 0;
      this.newInvoice.age10 = 0;
      this.newInvoice.age11 = 0;
      this.newInvoice.age12 = 0;
      this.newInvoice.age13 = 0;
      for (let i = 0; i < this.newInvoice.members.length; i++) {
        this.calcAgesSendBefore(this.newInvoice.members[i].rd, this.newInvoice.members[i].age); //nasiig dahin huwaarilah
      }
    }

    if (invoiceNo == 0 && this.selService.id == 2) {
      this.newInvoice.endDate =  this.yyyymmdd(this.addDays(new Date(), this.newInvoice.days));
      this.newInvoice.properties[0].address = this.getRefName(0, this.newInvoice.properties[0].off)+' '+
      this.getRefName(1, this.newInvoice.properties[0].sub)+', '+this.newInvoice.properties[0].town+' '+this.newInvoice.properties[0].apart+'-'+this.newInvoice.properties[0].number;
      this.newInvoice.properties[0].cat = this.propertyCat(this.newInvoice.properties[0].cat);
      this.newInvoice.protects = this.ps();
    }

    if (this.selService.id == 3) {
      this.newInvoice.endDate = this.yyyymmdd(this.addDays(new Date(), this.newInvoice.days));
    }

    if (this.selService.id == 4) {
      this.newInvoice.endDate = this.yyyymmdd(this.addDays(new Date(this.newInvoice.startDate), this.newInvoice.days));
    }

    if (this.selService.id == 5 && invoiceNo == 0) {
      this.newInvoice.protects = this.ps();
      this.newInvoice.local = this.localVisitor;
      this.newInvoice.days = this.newInvoice.days > 60 ? 60 : this.newInvoice.days;
    }

    this.newInvoice.serviceId = this.selService.id;
    this.gloading = true;
    this.tabIndex = 2;
    this.http.post(url, this.newInvoice, {headers: headers}).subscribe((res) => {
      this.paylinks = res;
      this.payLoading = false;
      this.payLoading1 = false;
      this.loading = false;
      // if (invoiceNo.length > 1)
      this.payCheck();

      if (!this.isBundle)
          this.getInvoices(this.pageNum);
    }, error => {
      this.loading= false;
      this.payLoading= false;
      this.toastr.error(error.statusText, 'Алдаа !');
    });
  }

  bundleForward() {
    if (this.isBundle) {
      if (this.selService.id == 3) {
        this.selService.id = 4;
        this.pageId = 1;
        this.selectedServiceId = 4;
        this.selectService(4);
        this.owners.splice(1, 1);
        this.companies = [];
        this.newInvoice.region = 'J1';
        this.selItemType = 'J1';
        this.fileInfos = [];
        this.newInvoice.days = 365;
        this.newInvoice.endDate =  this.yyyymmdd(this.addDays(new Date(), this.newInvoice.days));
        this.paylinks.qr = '';
        this.newInvoice.orgId = 0;
        this.isBundle = false;
      }
    }
  }

  prevPage() {
    if (this.pageId == 3 && this.newInvoice.id) return;

    this.pageId--;
    if (!this.newInvoice.id) {
      if (this.pageId == 2 && this.selService.id == 1) {
        this.newInvoice.age1 = 0;
        this.newInvoice.age2 = 0;
        this.newInvoice.age3 = 1;
        this.newInvoice.age4 = 0;
        this.newInvoice.age5 = 0;
        this.age1 = [];
        this.age2 = [];
        this.age3 = [];
        this.age4 = [];
        this.age5 = [];
        this.pushAges(this.age3, 3);
        this.newInvoice.days = 3;
      }
    }
  }

  isnotEmpty(v : any) {
    return v && v.length > 0;
  }

  ruleCheck(protectId: any) {
    for (let i = 0; i < this.rules.length; i++) {
      let r = this.rules[i];
      if (r.serviceId == this.selService.id) {
        if (r.visaType == parseInt(this.newInvoice.visaType)) {
          for (let k = 0; k < this.newInvoice.countries.length; k++) {
            if (this.newInvoice.countries[k].name == r.country && r.protectId == protectId) {
              //this.toastr.success(r.descr, 'Мэдэгдэл !');
              this.newInvoice.region = 'WW';
              return r.returnValue;
            }
          }
        }

        if (r.region == this.newInvoice.region && r.protectId != protectId) {
          this.toastr.error(r.descr, 'Алдаа !');
          return r.returnValue;
        }
      }
    }

    return true;
  }

  setOwnerGetter() {
    this.owners[1].fn = this.owners[0].fn;
    this.owners[1].ln = this.owners[0].ln;
    this.owners[1].rd = this.owners[0].rd;
    this.owners[1].ph = this.owners[0].ph;
    this.owners[1].em = this.owners[0].em;
  }

  contracNo(serviceId: any, orgId: any) {
    if (serviceId == 1)
      return 'CX23_'+(orgId < 10 ? '0'+orgId:orgId);

    if (serviceId == 3)
      return 'TX23_'+(orgId < 10 ? '0'+orgId:orgId);

    return 'CX23_'+(orgId < 10 ? '0'+orgId:orgId);
  }

  nextPage() {
    this.tabIndex = 1;
    if (!this.newInvoice.id) {
      if (this.pageId == 1 && this.selService.id == 1) {
        this.newInvoice.age1 = 0;
        this.newInvoice.age2 = 0;
        this.newInvoice.age3 = 1;
        this.newInvoice.age4 = 0;
        this.newInvoice.age5 = 0;
        this.age1 = [];
        this.age2 = [];
        this.age3 = [];
        this.age4 = [];
        this.age5 = [];
        this.pushAges(this.age3, 3);
        this.newInvoice.days = 3;

        if (this.newInvoice.region == '') {
          this.toastr.error('Зорчих улс сонгоно уу !', 'Алдаа !');
          return;
        }

       if (this.ruleCheck(this.newInvoice.protectId)) {
          // for (let i = 0; i < this.selService.packageList.length; i++) {
          //   let p = this.selService.packageList[i].protectList;
          //   for (let j = 0; j < p.length; j++) {
          //     if (p[j].id == this.newInvoice.protectId || this.newInvoice.protectId == 0) {
          //       if (p[j].regions && p[j].regions.indexOf(this.newInvoice.region) == -1) {
          //         this.toastr.error(this.selService.descr, 'Алдаа !');
          //         return;
          //       }
          //     }
          //   }
          // }
        } else return;
      }
      if (this.pageId == 1 && this.selService.id == 2) {
        this.properties[0].regno = this.propNo;

        if (!this.properties[0].regno) {
          this.toastr.error('Үл хөдлөх хөрөнгийн мэдээлэл оруулаагүй байна ! Жишээ Ү1234567890 байх ёстой.', 'Алдаа !');
          return;
        }

        if (this.propNo.length != 11 || !this.regexPropno(this.propNo)) {
          this.toastr.error('Үл хөдлөхийн дугаар буруу байна !', 'Алдаа !');
          return;
        }

        if (!this.properties[0].propYear) {
          this.toastr.error('Үл хөдлөх хөрөнгийн ашиглалтанд орсон огноо оруулаагүй байна !', 'Алдаа !');
          return;
        }

        if (this.properties[0].size == 0) {
          this.toastr.error('Үл хөдлөх хөрөнгийн тайлбай оруулаагүй байна !', 'Алдаа !');
          return;
        }

        if (!this.properties[0].off || !this.properties[0].sub || !this.properties[0].town || !this.properties[0].apart || !this.properties[0].number) {
          this.toastr.error('Үл хөдлөх хөрөнгийн хаяг оруулаагүй байна !', 'Алдаа !');
          return;
        }

        if (!this.owners[0].ln || !this.owners[0].fn || !this.owners[0].ph || !this.owners[0].rd) {
          this.toastr.error('Даатгуулагчийн мэдээлэл оруулаагүй байна !', 'Алдаа !');
          return;
        }

        this.getPropertyPics(this.propNo);

        this.properties[0].address =
          this.getRefName(0, this.properties[0].off)+' '+
          this.getRefName(1, this.properties[0].sub)+', '+this.properties[0].town+' '+this.properties[0].apart+'-'+this.properties[0].number;
      }
      if (this.pageId == 1 && this.selService.id == 3) {
        if (!this.newInvoice.protectId) {
          this.toastr.error('Хамгаалалтын төрөл сонгоно уу !', 'Алдаа !');
          return;
        }
      }
      if (this.pageId == 1 && this.selService.id == 5) {
        if (this.newInvoice.protectId == 0) {
          this.toastr.error('Хамгаалалтын төрөл сонгоно уу !', 'Алдаа !');
          return;
        }
      }
      if (this.pageId == 2 && this.companies.length == 0) {
        if (this.selService.id == 3 || this.selService.id == 2) {
          if (this.properties[0].propYear == '') {
            this.toastr.error('Тээврийн хэрэгслийн мэдээлэл оруулна уу !', 'Алдаа !');
            return;
          }
          if (this.fileInfos.length == 0) {
            this.toastr.error('Эд хөрөнгийн дор хаяж 1 зураг оруулна уу !', 'Алдаа !');
            return;
          }
          if (this.newInvoice.orgId == 0) {
            this.toastr.error('Тооцоололыг хийж даатгалын компани сонгон уу !', 'Алдаа !');
            return;
          }
        }
      }
      if (this.pageId == 2 && this.companies.length > 0) {
        if (this.selService.id == 2) {
          if (this.fileInfos.length == 0) {
            this.toastr.error('Эд хөрөнгийн дор хаяж 1 зураг оруулна уу !', 'Алдаа !');
            return;
          }
        }

        if (this.newInvoice.orgId == 0) {
          this.toastr.error('Даатгалын компани сонгон уу !', 'Алдаа !');
          return;
        }
        if (this.selService.id == 2 || this.selService.id == 3)
          this.getPropertyPics(this.propNo);

        if (this.selService.id == 5) {
          if (this.newInvoice.locals.passNo == '') {
            this.toastr.error('Регистрын дугаар оруулна уу !', 'Алдаа !');
            return;
          }
          if (this.newInvoice.locals.firstName == '' || this.newInvoice.locals.lastName == '') {
            this.toastr.error('Овог, нэр оруулна уу !', 'Алдаа !');
            return;
          }
          if (this.newInvoice.locals.phoneNo == '') {
            this.toastr.error('Утасны дугаар оруулна уу !', 'Алдаа !');
            return;
          }
          if (this.newInvoice.countries.length == 0) {
            this.toastr.error('Аялах аймаг сонгоно уу !', 'Алдаа !');
            return;
          }

          this.sendInvoice(0);
        }
      }
      if (this.pageId == 2 && this.selService.id == 2) {
        if (this.fileInfos.length == 0) {
          this.toastr.error('Эд хөрөнгийн дор хаяж 1 зураг оруулна уу !', 'Алдаа !');
          return;
        }
      }

      if (this.selService.id == 1)
        this.getGroups();

      if (this.pageId == 3 && this.selService.id == 1 && this.paylinks.qr == '') {
        if (this.age1.length > 0) {
          for (let i = 0; i < this.age1.length; i++) {
            let a = this.age1[i];
            if (!this.isnotEmpty(a.rd) || !this.isnotEmpty(a.fn) || !this.isnotEmpty(a.ln) || !this.isnotEmpty(a.ep)) {
              this.toastr.error('Даатгуулагчдын мэдээлэл оруулна уу !', 'Алдаа !');
              return;
            }
            this.error['rd1'+i] = a.fgn == 0 ? !this.regexRegno(a.rd) ? 'is-error':'' : '';
            if (this.error['rd1'+i] == 'is-error') return;
          }
        }
        if (this.age2.length > 0) {
          for (let i = 0; i < this.age2.length; i++) {
            let a = this.age2[i];
            if (!this.isnotEmpty(a.rd) || !this.isnotEmpty(a.fn) || !this.isnotEmpty(a.ln) || !this.isnotEmpty(a.ep)) {
              this.toastr.error('Даатгуулагчдын мэдээлэл оруулна уу !', 'Алдаа !');
              return;
            }

            this.error['rd2'+i] = a.fgn == 0 ? !this.regexRegno(a.rd) ? 'is-error':'' : '';
            if (this.error['rd2'+i] == 'is-error') return;
          }
        }
        if (this.age3.length > 0) {
          for (let i = 0; i < this.age3.length; i++) {
            let a = this.age3[i];
            if (!this.isnotEmpty(a.rd) || !this.isnotEmpty(a.fn) || !this.isnotEmpty(a.ln) || !this.isnotEmpty(a.ep)) {
              this.toastr.error('Даатгуулагчдын мэдээлэл оруулна уу !', 'Алдаа !');
              return;
            }

            this.error['rd3'+i] = a.fgn == 0 ? !this.regexRegno(a.rd) ? 'is-error':'' : '';
            if (this.error['rd3'+i] == 'is-error') return;
          }
        }
        if (this.age4.length > 0) {
          for (let i = 0; i < this.age4.length; i++) {
            let a = this.age4[i];
            if (!this.isnotEmpty(a.rd) || !this.isnotEmpty(a.fn) || !this.isnotEmpty(a.ln) || !this.isnotEmpty(a.ep)) {
              this.toastr.error('Даатгуулагчдын мэдээлэл оруулна уу !', 'Алдаа !');
              return;
            }

            this.error['rd4'+i] = a.fgn == 0 ? !this.regexRegno(a.rd) ? 'is-error':'' : '';
            if (this.error['rd4'+i] == 'is-error') return;
          }
        }
        if (this.age5.length > 0) {
          for (let i = 0; i < this.age5.length; i++) {
            let a = this.age5[i];
            if (!this.isnotEmpty(a.rd) || !this.isnotEmpty(a.fn) || !this.isnotEmpty(a.ln) || !this.isnotEmpty(a.ep)) {
              this.toastr.error('Даатгуулагчдын мэдээлэл оруулна уу !', 'Алдаа !');
              return;
            }

            this.error['rd5'+i] = a.fgn == 0 ? !this.regexRegno(a.rd) ? 'is-error':'' : '';
            if (this.error['rd5'+i] == 'is-error') return;
          }
        }

        this.sendInvoice(0);
        return;
      }
      if (this.pageId == 3 && this.selService.id == 2 && this.paylinks.qr == '') {
        this.sendInvoice(0);
        return;
      }
      if (this.pageId == 3 && this.selService.id == 3 && this.paylinks.qr == '') {
        if (this.fileInfos.length == 0) {
          this.toastr.error('Эд хөрөнгийн дор хаяж 1 зураг оруулна уу !', 'Алдаа !');
          return;
        }

        let a = this.owners[0];
        if (!this.isnotEmpty(a.rd)) {
          this.toastr.error('Өмчлөгчийн регистр оруулна уу !', 'Алдаа !');
          return;
        }
        if (!this.isnotEmpty(a.ln) && a.rd.length == 10 && this.newInvoice.properties[0].ownerType == 'Хувь хүн') {
          this.toastr.error('Өмчлөгчийн овог оруулна уу !', 'Алдаа !');
          return;
        }

        if (!this.isnotEmpty(a.fn)) {
          this.toastr.error('Өмчлөгчийн нэр оруулна уу !', 'Алдаа !');
          return;
        }

        if (!a.ph) {
          this.toastr.error('Өмчлөгчийн утасны дугаар оруулна уу !', 'Алдаа !');
          return;
        }

        if (this.owners[1].rd == '' && this.newInvoice.properties[0].ownerType == 'Хувь хүн' && confirm('Даатгуулагч болон өмчлөгч ижил бол тийм дарна !')) {
          this.setOwnerGetter();
          this.owners[1].ownerType = 2;
        } else {
          let b = this.owners[1];
          if (!this.isnotEmpty(b.rd) || !this.isnotEmpty(b.fn) || (!this.isnotEmpty(b.ln) && this.properties[0].ownerType == 'Хувь хүн') || !b.ph) {
            this.toastr.error('Эзэмшигчийн мэдээлэл оруулна уу !', 'Алдаа !');
            return;
          }
        }

        this.newInvoice.members = this.owners;
        this.sendInvoice(0);
        return;
      }
      if (this.pageId == 3 && this.selService.id == 4 && this.paylinks.qr == '') {
        for (let i = 0; i < this.owners.length; i++) {
          if (!this.owners[i].rd || !this.owners[i].fn || !this.owners[i].ln || !this.owners[i].ph || !this.owners[i].tr) {
            this.toastr.error('Жолооч нарын мэдээлэл дутуу байна !', 'Алдаа !');
            return;
          }
        }

        this.sendInvoice(0);
        return;
      }
    }

    this.pageId++;
  }

  downloadInvoice(invoiceNo: any) {
    window.open("https://daatguul.com/p/v1/invoice/pdf/"+invoiceNo);
  }

  payCheck() {
    const url = 'p/v1/invoice/payed/'+this.paylinks.invoiceNo;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer '+this.token
    })
    this.gloading = true;
    if (this.selectedServiceId == 3)
      this.payLoading1 = true;
    this.http.get(url, {headers: headers}).subscribe((res) => {
      this.payresp = res;
      this.gloading = false;
      this.payLoading1 = false;
      if (this.selService.id != 1 && this.payresp.properties.length > 0)
        this.getPropertyPics(this.payresp.properties[0].regno);

      if (this.payresp.payStatus == 2) {
        if (this.payresp.dkSent == 0) {
          this.toastr.warning('Имейл илгээгдээгүй байна !', 'Ok');
          return;
        }
        if (this.payresp.dkSent == 1) {
          this.toastr.success('Имейл илгээгдсэн байна !', 'Ok');
          return;
        }
        this.toastr.success('Төлөлгдсөн байна !', 'Ok');
        return;
      }
      if (this.payresp.payStatus != 2) {
        this.toastr.error('Төлөгдөөгүй байна !', 'Алдаа !');
        return;
      }
    }, error => {
      this.gloading = false;
      this.payLoading1= false;
      this.toastr.error(error.statusText, 'Алдаа !');
    });
  }

  payPostpaid() {
    if (confirm('Дараа төлөх төлөвт шилжүүлэх үү ? Амжилттай шилжсэн бол НЭХЭМЖИЛСЭН цэснээс харна уу !')) {
      const url = 'p/v1/invoice/postpaid/' + this.paylinks.invoiceNo;
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token
      })
      this.gloading = true;
      this.payLoading1 = true;
      this.http.get(url, {headers: headers}).subscribe((res) => {
        this.gloading = false;
        this.payLoading1 = false;
        this.resp = res;
        if (this.resp.code == 0) {
          this.toastr.success(this.resp.msg, 'Ok');
          location.href = '/invoice';
        } else
        if (this.resp.code != 0)
          this.toastr.error(this.resp.msg, 'Алдаа !');

      }, error => {
        this.gloading = false;
        this.payLoading1 = false;
        this.toastr.error(error.statusText, 'Алдаа !');
      });
    }
  }

  companySelect(company:any) {
    this.newInvoice.orgId = company.id;
    this.newInvoice.amount = company.total;
    if (this.selService.id == 3) {
      this.newInvoice.visaType = parseInt(company.region);
    }
  }

  viewInvoice(invoice:any) {
    this.payresp = {};
    this.paylinks = {qr:''};
    this.age1 = [];
    this.age2 = [];
    this.age3 = [];
    this.age4 = [];
    this.age5 = [];
    this.loading = true;
    this.gloading = true;
    const url = 'p/v1/invoice/get/'+invoice.invoiceNo;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + this.token
    })
    this.http.get(url, {headers: headers}).subscribe((res) => {
      this.newInvoice = res;
      this.gloading = false;
      for (let i = 0 ; i < this.services.length; i++) {
        if (this.services[i].id == invoice.serviceId) {
          this.selService = this.services[i];
        }
      }
      let protect, protects;
      for (let i = 0; i < this.selService.packageList.length; i++) {
        let p = this.selService.packageList[i].protectList;
        for (let j = 0; j < p.length; j++) {
          if (p[j].id == this.newInvoice.protect.protectId) {
            protects = p;
            protect = p[j];
          }
        }
      }

      this.age1 = [];
      this.age2 = [];
      this.age3 = [];
      this.age4 = [];
      this.age5 = [];
      this.age12 = [];
      this.owners = [];
      for (let i = 0; i < this.newInvoice.users.length; i++) {
        let u = this.newInvoice.users[i];

        if (this.newInvoice.serviceId == 1) {
          if (u.ageType == 1)
            this.age1.push({
              id: u.id,
              fn: u.firstname,
              ln: u.lastname,
              rd: u.regno,
              ep: u.epassno,
              ph: u.phone,
              em: u.email,
              sd: u.startDate,
              ed: u.endDate,
              type: 1,
              fgn: u.fgn,
              sent: u.sent,
              status: u.status,
              usd: u.amountUsd
            });
          if (u.ageType == 2)
            this.age2.push({
              id: u.id,
              fn: u.firstname,
              ln: u.lastname,
              rd: u.regno,
              ep: u.epassno,
              ph: u.phone,
              em: u.email,
              sd: u.startDate,
              ed: u.endDate,
              type: 2,
              fgn: u.fgn,
              sent: u.sent,
              status: u.status,
              usd: u.amountUsd
            });
          if (u.ageType == 3)
            this.age3.push({
              id: u.id,
              fn: u.firstname,
              ln: u.lastname,
              rd: u.regno,
              ep: u.epassno,
              ph: u.phone,
              em: u.email,
              sd: this.yyyymmdd(new Date(u.startDate)),
              ed: this.yyyymmdd(new Date(u.endDate)),
              fgn: u.fgn,
              type: 3,
              sent: u.sent,
              status: u.status,
              usd: u.amountUsd
            });

          if (u.ageType == 4)
            this.age4.push({
              id: u.id,
              fn: u.firstname,
              ln: u.lastname,
              rd: u.regno,
              ep: u.epassno,
              ph: u.phone,
              em: u.email,
              sd: u.startDate,
              ed: u.endDate,
              fgn: u.fgn,
              type: 4,
              sent: u.sent,
              status: u.status,
              usd: u.amountUsd
            });

          if (u.ageType == 5)
            this.age5.push({
              id: u.id,
              fn: u.firstname,
              ln: u.lastname,
              rd: u.regno,
              ep: u.epassno,
              ph: u.phone,
              em: u.email,
              sd: u.startDate,
              ed: u.endDate,
              fgn: u.fgn,
              type: 5,
              sent: u.sent,
              status: u.status,
              usd: u.amountUsd
            });

          if (u.ageType == 12) {
            this.age12.push({
              id: u.id,
              fn: u.firstname,
              ln: u.lastname,
              rd: u.regno,
              ep: u.epassno,
              ph: u.phone,
              em: u.email,
              sd: u.startDate,
              ed: u.endDate,
              fgn: u.fgn,
              type: 12,
              sent: u.sent,
              status: u.status,
              usd: u.amountUsd
            });
          }
        } else {
          this.owners.push({
            id: u.id,
            fn: u.firstname,
            ln: u.lastname,
            rd: u.regno,
            ep: u.epassno,
            ph: u.phone,
            em: u.email,
            sd: u.startDate,
            ed: u.endDate,
            fgn: u.fgn,
            type: 0,
            sent: u.sent,
            status: u.status,
            ownerType: u.ownerType,
            usd: u.amountUsd
          });
        }
      }

      this.protectSelect(protects, protect);
      this.sendInvoice(this.newInvoice.invoiceNo);
      this.pageId = 3;
      this.loading = false;
    }, error => {
      this.loading = false;
      this.toastr.error(error.statusText, 'Алдаа !');
    });
  }

  deleteInvoice(invoice:any) {
    if (confirm('Устгах уу ?')) {
      this.loading = true;
      const url = 'p/v1/invoice/' + invoice.invoiceNo;
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token
      })
      this.http.delete(url, {headers: headers}).subscribe((res) => {
        this.getInvoices(this.pageNum);
      }, error => {
        this.loading = false;
        this.toastr.error(error.statusText, 'Алдаа !');
      });
    }

  }

  getInvoices(pageNum:any) {
    this.pageNum = pageNum;
    this.invoices = {};
    this.invoiceLoading = true;
    const url = 'p/v1/invoice/all/' + this.status+'/'+this.selOrgId+'/'+this.selAgentId+'/'+this.pageNum;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer '+this.token
    })
    this.pages = [];
    this.http.get(url, {headers: headers}).subscribe((res) => {
      this.invoices = res;
      this.firstload = true;
      this.invoiceLoading = false;
      for (let i = 0; i < this.invoices.rows / this.invoices.size; i++)
        this.pages.push({page: i+1});
    }, error => {
      this.invoiceLoading = false;
      this.toastr.error(error.statusText, 'Алдаа !');
    });
  }

  getGroups() {
    this.gloading = true;
    this.kloading = true;
    const url = 'p/groupList';
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer '+this.token
    })

    this.http.get(url, {headers: headers}).subscribe((res) => {
      this.groups = res;
      this.kloading = false;
      this.loading = false;
      this.gloading = false;
      this.aploading = false;
    }, error => {
      this.kloading = false;
      this.gloading = false;
      this.toastr.error(error.statusText, 'Алдаа !');
    });
  }


  editInvoiceUser(age: any, index: any) {
    if (age.status != 2)
      age.status = 2;
    else
    if (age.status == 2) {
      if (age.fgn == 0) {
        this.error['rd'+age.type+index] = !this.regexRegno(age.rd) ? 'is-error' : '';
        if (this.error['rd'+age.type+index] == 'is-error') return;
      }

      age.status = 1;
      const url = 'p/v1/invoice/user/update';
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '+this.token
      })
      this.loading = true;
      this.companies = [];
      this.http.post(url, age, {headers: headers}).subscribe((res) => {
        this.resp = res;
        if (this.resp.code == 1)
          this.toastr.error(this.resp.msg, 'Алдаа !');
        if (this.resp.code == 0)
          this.toastr.success(this.resp.msg, 'Ok !');
        this.loading = false;
      }, error => {
        this.loading= false;
        this.toastr.error(error.statusText, 'Алдаа !');
      });
    }
  }

  removeInvoiceUser(user: any) {
    if (confirm('Даатгалын гэрээнээс хасах уу !')) {
      const url = 'p/v1/invoice/edit/' + this.payresp.invoiceNo;
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '+this.token
      })
      this.loading = true;
      this.http.post(url, user, {headers: headers}).subscribe((res) => {
        this.resp = res;
        this.toastr.success(this.resp.msg, 'Алдаа !');
        this.loading = false;

        location.href = '/'+this.page;
      }, error => {
        this.loading= false;
        this.toastr.error(error.statusText, 'Алдаа !');
      });
    }
  }

  serviceShortName(name: any) {
    if (name == 'Гадаад зорчигчийн даатгал') return 'ГЗ Даатгал';
    if (name == 'Тээврийн хэрэгслийн даатгал') return 'ТХ Даатгал';
    if (name == 'Үл хөдлөх хөрөнгийн даатгал') return 'ҮХХ Даатгал';
    if (name == 'Жолоочийн хариуцлагын даатгал') return 'ЖХ Даатгал';
    if (name == 'Дотоод аялагчийн даатгал') return 'ДА Даатгал';

    return '';
  }

  propertyIsChecked(propNo:any) {
    for (let i = 0; i < this.properties.length; i++) {
      if (this.properties[i].regno == propNo)
        return true;
    }

    return false;
  }

  propertyIt(propNo:any) {
    for (let i = 0; i < this.properties.length; i++) {
      if (this.properties[i].regno == propNo)
        return this.properties[i];
    }

    return {
      type: 4,
      regno: '',
      amount: 0,
      size: 0,
      address: '',
      mark: '',
      model: '',
      propYear: '',
      quality: 1,
      safety: 1,
      location: 1,
      capacity: 0,
      extend: 0
    };
  }


  searchProperty() {
    //ү2206069120
    if (this.properties.length == 0) {
      this.properties.push({
        type: 4,
        regno: '',
        amount: 0,
        size: 0,
        address: '',
        mark: '',
        model: '',
        propYear: '',
        quality: 1,
        safety: 1,
        location: 1,
        capacity: 0,
        extend: 0
      });
    }


    if (this.selService.id == 2) {
      if (this.propNo.length != 11 || !this.regexPropno(this.propNo)) {
        this.toastr.error('Үл хөдлөхийн дугаар буруу байна !', 'Алдаа !');
        return;
      }

      this.properties[0].regno = this.propNo;
      this.properties[0].number = '';
      this.properties[0].apart = '';
      this.properties[0].off = '';
      this.properties[0].sub = '';
      return;
    }

    // this.properties[0].regno = '';
    this.gloading = true;
    const url = 'p/v1/tpi/property/'+this.selService.id+'/'+this.propNo+'/'+(this.ownerRegno|this.propNo);
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer '+this.token
    })
    this.loading = true;
    this.respTpi = {
      propertyNumber: '',
      size: '',
      intent: '',
      address: '',
      model: '',
      mark: '',
      cat: 1,
      weight: '',
      vin: '',
      color: '',
      propertyProcessList: [
        {
          date: '',
          date1: '',
          propertyOwnerDtoList: [
            {
               firstname: '',
               lastname: '',
               registerNumber: ''
            }
          ]
        }
      ]
    };

    this.users = [];
    this.http.get(url,{headers: headers}).subscribe((res) => {
      this.respTpi = res;
      if (!this.respTpi.model) {
        this.toastr.error('Мэдээлэл олдсонгүй', 'Алдаа !');
        return;
      }

      this.gloading = false;
      if (this.plates.length == 0) {
        this.properties[0] = {
          type: this.selService.id,
          cat: this.respTpi.cat,
          mark: this.respTpi.mark,
          model: this.respTpi.model,
          regno: this.respTpi.propertyNumber,
          amount: this.respTpi.amount,
          size: this.respTpi.size,
          address: this.respTpi.address,
          propYear: this.selService.id == 2 ? this.respTpi.propertyProcessList[0].date : this.respTpi.propYear,
          mnYear: this.selService.id == 2 ? this.respTpi.propertyProcessList[0].date1 : this.respTpi.mnYear,
          weight: this.respTpi.weight,
          color: this.respTpi.color,
          vin: this.respTpi.vin,
          ownerType: this.respTpi.ownerType,
          quality: 1,
          location: 1,
          safety: 1,
          extend: 0,
          capacity: this.respTpi.capacity,
          ownerRegno: this.respTpi.propertyProcessList[0].propertyOwnerDtoList[0].registerNumber,
          ownerFirstName: this.respTpi.propertyProcessList[0].propertyOwnerDtoList[0].firstname,
          ownerLastName: this.respTpi.propertyProcessList[0].propertyOwnerDtoList[0].lastname
        };
      } else {
        if (!this.respTpi.mark) {
          this.toastr.error(this.propNo+' мэдээлэл олдсонгүй', 'Алдаа !');
          return;
        }
        if (this.properties[0].propYear == '') {
          console.log(this.propNo);
          this.properties[0] = {
            type: this.selService.id,
            cat: this.respTpi.cat,
            mark: this.respTpi.mark,
            model: this.respTpi.model,
            regno: this.propNo,
            amount: this.respTpi.amount,
            size: this.respTpi.size,
            address: this.respTpi.address,
            propYear: this.selService.id == 2 ? this.respTpi.propertyProcessList[0].date : this.respTpi.propYear,
            mnYear: this.selService.id == 2 ? this.respTpi.propertyProcessList[0].date1 : this.respTpi.mnYear,
            weight: this.respTpi.weight,
            color: this.respTpi.color,
            vin: this.respTpi.vin,
            ownerType: this.respTpi.ownerType,
            quality: 1,
            location: 1,
            safety: 1,
            extend: 0,
            capacity: this.respTpi.capacity,
            ownerRegno: this.respTpi.propertyProcessList[0].propertyOwnerDtoList[0].registerNumber,
            ownerFirstName: this.respTpi.propertyProcessList[0].propertyOwnerDtoList[0].firstname,
            ownerLastName: this.respTpi.propertyProcessList[0].propertyOwnerDtoList[0].lastname
          };
        } else {
          this.properties.push({
            type: this.selService.id,
            cat: this.respTpi.cat,
            mark: this.respTpi.mark,
            model: this.respTpi.model,
            regno: this.propNo,
            amount: this.respTpi.amount,
            size: this.respTpi.size,
            address: this.respTpi.address,
            propYear: this.selService.id == 2 ? this.respTpi.propertyProcessList[0].date : this.respTpi.propYear,
            mnYear: this.selService.id == 2 ? this.respTpi.propertyProcessList[0].date1 : this.respTpi.mnYear,
            weight: this.respTpi.weight,
            color: this.respTpi.color,
            vin: this.respTpi.vin,
            ownerType: this.respTpi.ownerType,
            quality: 1,
            location: 1,
            safety: 1,
            extend: 0,
            capacity: this.respTpi.capacity,
            ownerRegno: this.respTpi.propertyProcessList[0].propertyOwnerDtoList[0].registerNumber,
            ownerFirstName: this.respTpi.propertyProcessList[0].propertyOwnerDtoList[0].firstname,
            ownerLastName: this.respTpi.propertyProcessList[0].propertyOwnerDtoList[0].lastname
          });
        }
      }

      this.owners = [];
      let ownerList = this.respTpi.propertyProcessList[this.respTpi.propertyProcessList.length - 1].propertyOwnerDtoList;
      for (let  i = 0; i < ownerList.length; i++) {
        this.owners.push({
          rd: ownerList[i].registerNumber,
          fn: ownerList[i].firstname,
          ln: ownerList[i].lastname,
          em: '',
          tr: 1,
          ownerType: ownerList[i].ownerType
        });
      }

      if (this.selService.id  == 3) {
        this.owners[0].rd = this.profile.orgDto.regno;
        this.owners[0].fn = this.profile.orgDto.name;
        this.owners[0].em = this.profile.orgDto.email;
        this.owners[0].ph = this.profile.orgDto.phone;
      }

      this.loading = false;
    }, error => {
      this.loading= false;
      this.toastr.error(error.statusText, 'Алдаа !');
    });
  }

  searchProperty1() {
    //ү2206069120
    if (this.selService.id == 2) {
      if (this.propNo.length != 11 || !this.regexPropno(this.propNo)) {
        this.toastr.error('Үл хөдлөхийн дугаар буруу байна !', 'Алдаа !');
        return;
      }

      this.properties[0].regno = this.propNo;
      this.properties[0].number = '';
      this.properties[0].apart = '';
      this.properties[0].off = '';
      this.properties[0].sub = '';
      return;
    }

    if (this.selService.id == 3) {
      if (this.propNo.length < 10) {
        this.toastr.error('Арлын дугаар буруу байна !', 'Алдаа !');
        return;
      }
    }

    this.properties[0].regno = '';
    const url = 'p/v1/tpi/property/'+this.selService.id+'/'+this.properties[0].vin+'/'+(this.ownerRegno|this.propNo);
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer '+this.token
    })
    this.loading = true;
    this.respTpi = {
      propertyNumber: '',
      size: '',
      intent: '',
      address: '',
      model: '',
      mark: '',
      cat: 1,
      weight: '',
      vin: '',
      color: '',
      propertyProcessList: [
        {
          date: '',
          date1: '',
          propertyOwnerDtoList: [
            {
              firstname: '',
              lastname: '',
              registerNumber: ''
            }
          ]
        }
      ]
    };

    this.users = [];
    this.http.get(url,{headers: headers}).subscribe((res) => {
      this.respTpi = res;
      this.properties[0] = {
        type: this.selService.id,
        cat: this.respTpi.cat,
        mark: this.respTpi.mark,
        model: this.respTpi.model,
        regno: this.respTpi.propertyNumber,
        amount: this.respTpi.amount,
        size: this.respTpi.size,
        address: this.respTpi.address,
        propYear: this.selService.id == 2 ? this.respTpi.propertyProcessList[0].date : this.respTpi.propYear,
        mnYear: this.selService.id == 2 ? this.respTpi.propertyProcessList[0].date1 : this.respTpi.mnYear,
        weight: this.respTpi.weight,
        color: this.respTpi.color,
        vin: this.respTpi.vin,
        ownerType: this.respTpi.ownerType,
        quality: 1,
        location: 1,
        safety: 1,
        extend: 0,
        capacity: this.respTpi.capacity,
        ownerRegno: this.respTpi.propertyProcessList[0].propertyOwnerDtoList[0].registerNumber,
        ownerFirstName: this.respTpi.propertyProcessList[0].propertyOwnerDtoList[0].firstname,
        ownerLastName: this.respTpi.propertyProcessList[0].propertyOwnerDtoList[0].lastname
      };

      this.owners = [];
      let ownerList = this.respTpi.propertyProcessList[this.respTpi.propertyProcessList.length - 1].propertyOwnerDtoList;
      for (let  i = 0; i < ownerList.length; i++) {
        this.owners.push({
          rd: ownerList[i].registerNumber,
          fn: ownerList[i].firstname,
          ln: ownerList[i].lastname,
          em: '',
          ownerType: ownerList[i].ownerType
        });
      }

      this.loading = false;
    }, error => {
      this.loading= false;
      this.toastr.error('Арлын дугаараар хайж байгаа бол 2021/01/01 хойш орж ирсэн машиныг хайна уу !', 'Алдаа !');
    });
  }

  getXls(p:any) {
    const url = 'p/v1/download/xls/'+p.invoiceNo;
    window.open(url);
    // const headers = new HttpHeaders({
    //   'Content-Type': 'application/json',
    //   'Authorization': 'Bearer '+this.token
    // })
    // this.loading = true;
    // this.http.get(url, {headers: headers}).subscribe((res) => {
    //   window.open('img/'+this.payresp.invoiceNo+'.xlsx');
    //   this.loading = false;
    // }, error => {
    //   this.loading = false;
    //   window.open('img/'+this.payresp.invoiceNo+'.xlsx');
    // });
  }

  checkAge(regno: any) {
    regno = regno.substring(regno.length - 8, 8);
    let t = parseInt(regno.substring(0, 2));
    let m = parseInt(regno.substring(2, 4));
    let d = parseInt(regno.substring(4, 6));
    if (t < 30) t = 2000 + t;
    else t = 1900 + t;
    if (m > 20) m = m - 20;
    var ty = new Date().getUTCFullYear();
    var my = new Date().getMonth()+1;
    var dy = new Date().getDate();

    if (my < m)
      return (ty - t - 1) < 0 ? 0 : (ty - t - 1);
    if (my == m && dy < d)
      return (ty - t - 1) < 0 ? 0 : (ty - t - 1);
    if (my == m && dy == d)
      return (ty - t) < 0 ? 0 : ty - t;

    return (ty - t) < 0 ? 0 : ty - t;
  }

  checkAgeDate(regno: any, date: any) {
    regno = regno.substring(regno.length - 8, 8);
    let t = parseInt(regno.substring(0, 2));
    let m = parseInt(regno.substring(2, 4));
    let d = parseInt(regno.substring(4, 6));
    if (t < 30) { t = 2000 + t; if (m > 12) m = m - 20; }
    else t = 1900 + t;
    var ty = date.getUTCFullYear();
    var my = date.getMonth()+1;
    var dy = date.getDate();
    if (my < m)
      return (ty - t - 1) < 0 ? ((my - m) / 10) : (ty - t - 1);
    if (my == m && d < dy)
      return (ty - t - 1) < 0 ? 0 : (ty - t - 1);
    if (my == m && dy == d)
      return (ty - t) < 0 ? 0 : ty - t;

    return (ty - t) < 0 ? 0 : ty - t;
  }

  checkAgeMonth(regno: any) {
    regno = regno.substring(regno.length - 8, 8);
    let t = parseInt(regno.substring(0, 2));
    let m = parseInt(regno.substring(2, 4));
    let d = parseInt(regno.substring(4, 6));
    if (t < 30) t = 2000 + t;
    else t = 1900 + t;
    if (m > 20) m = m - 20;
    var ty = new Date().getUTCFullYear();
    var my = new Date().getMonth()+1;
    var dy = new Date().getDate();

    if (t == ty)
      return my - m;

    return 0;
  }

  findUserRd(user: any) {
    if (user.rd.length != 10) return;
    const url = 'p/userList/search/'+user.rd;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer '+this.token
    });


    this.http.get(url, {headers: headers}).subscribe((res) => {
      this.matched = res;
      if (this.matched.rows > 0) {
        user.userId = this.matched.userDtoList[0].id;
        user.fn = this.matched.userDtoList[0].firstname;
        user.ln = this.matched.userDtoList[0].lastname;
        user.ph = this.matched.userDtoList[0].phone;
        user.em = this.matched.userDtoList[0].email;
      }
      this.loading = false;
    }, error => {
      this.loading = false;
      this.toastr.error(error.statusText, 'Алдаа !');
    });
  }

  regexRegno(regno: any) {
    let regex = /[А-ЯӨҮ]{2}[0-9]{8}/gm;
    return regno.match(regex);
  }

  regexRegnoChange(value: any, i: any, user: any) {
    if (this.properties[0].ownerType == 'Хувь хүн' && value.length > 7) {
      this.errorText = '';
      let regex = /[А-ЯӨҮ]{2}[0-9]{8}/gm;
      if (!value.match(regex)) this.errorText = 'Регистрийн дугаарыг зөв оруулна уу !';
      this.error['rd1' + i] = value.match(regex) ? '' : 'is-error';

      if (value.match(regex)) {
        this.findUserRd(user);
      }
    } else {
      this.errorText = '';
      let regex = /[0-9]{7}/gm;
      if (!value.match(regex)) this.errorText = 'Регистрийн дугаарыг зөв оруулна уу !';
      this.error['rd1' + i] = value.match(regex) ? '' : 'is-error';
    }
  }

  regexCyrillChange(value: any, al: any) {
    this.errorText = '';
    let regex = /[А-ЯӨҮ]/gm;
    if (!value.match(regex)) this.errorText = 'Овог, нэрийг кириллээр зөв оруулна уу !';
    this.error[al] = value.match(regex) ? '':'is-error';
    return value;
  }

  regexPropno(regno: any) {
    regno = regno.toUpperCase();
    let regex = /[Ү]{1}[0-9]{10}/gm;
    return regno.match(regex);
  }

  regexEmail(email: any) {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email))
      return true;

    return false;
  }


}
